<template>
  <div class="wrapper form" style="padding-top: 0px;">
    <div class="grid grid-cols-3">
      <div class="col-span-3 grid grid-cols-3">
        <div class="col-span-3" v-if="contactSwitch">
          <el-tabs v-model="edit_entity.type" stretch>
            <el-tab-pane :label="$t('private')" name="private" />
            <el-tab-pane :label="$t('business')" name="business" />
          </el-tabs>
        </div>

        <div class="col-span-3 mb-4" v-if="useContacts">
          <core-autocomplete
            endpoint="contacts"
            paginate
            valueKey="id"
            labelKey="name"
            orderBy="name"
            orderDir="asc"
            :value="contact"
            @update:value="val => (contact = val, contact.phone = val.phone_with_prefix)"
            :clearable="true"
            @clear="clear()"
            :placeholder="
              $t('select_entity', { entity: $tc('contact').toLowerCase() })
            "
          />
        </div>

        <div class="col-span-3 mb-4" v-if="edit_entity.type === 'business'">
          <core-text
            :label="$t('company_name')"
            v-model="edit_entity.company_name"
            @input="update"
          />
        </div>

        <div class="col-span-3 mb-4">
          <core-text
            :readonly="readonly"
            :label="
              edit_entity.type === 'business' ? $t('attention') : $t('name')
            "
            v-model="edit_entity.name"
            @input="update"
          />
        </div>
        <div class="col-span-3 mb-4">
          <core-text
            :readonly="readonly"
            :label="`${$t('adress_line')} 1`"
            v-model="edit_entity.street1"
            @input="update"
          />
        </div>
        <div
          class="col-span-3 mb-4"
          v-if="edit_entity.type === 'business' || edit_entity.country !== 'DK'"
        >
          <core-text
            :readonly="readonly"
            :label="`${$t('adress_line')} 2`"
            v-model="edit_entity.street2"
            @input="update"
          />
        </div>
        <div class="col-span-3 md:col-span-1 mb-4">
          <core-text
            :readonly="readonly"
            :label="$t('abrreviations.zip_code')"
            v-model="edit_entity.zip_code"
            @input="
              val => autoCity(val, edit_entity.country, update, toggleCityField)
            "
          />
        </div>
        <div class="col-span-3 md:col-span-2 mb-4 ml-4">
          <core-text
            :readonly="readonly"
            :label="$t('city')"
            v-model="edit_entity.city"
            @input="update"
            :disabled="!cityEnabled"
          />
        </div>
        <div class="col-span-3 mb-4">
          <core-text
            :readonly="readonly"
            :label="$t('email')"
            v-model="edit_entity.email"
            @input="update"
          />
        </div>
        <div class="col-span-3 mb-4">
          <core-select-country
            :disabled="readonly"
            :label="$t('country')"
            :value.sync="edit_entity.country"
            @change="val => (update('country', val), updatePrefix(val))"
            :endpoint="countryEndpoint"
            :excludeAllNonEU="excludeAllNonEU"
          />
        </div>

        <div class="col-span-3 mb-4">
          <core-text
            :readonly="readonly"
            :label="`${$t('phone')}`"
            v-model="edit_entity.phone"
            @input="update"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { autoCity } from "@/utils/tools";
import _ from "lodash";
const defaultContactObject = {
  contact_id: null,
  name: null,
  street1: null,
  street2: null,
  zip_code: null,
  city: null,
  country: null,
  phone: null,
  email: null
};

export default {
  name: "Contact",
  props: {
    showCountry: {
      type: Boolean,
      default: true
    },
    defaultType: {
      type: String,
      default: "private"
    },
    defaultCountry: {
      type: String,
      default: "DK"
    },
    entity: {
      type: Object,
      default: () => ({})
    },
    useContacts: {
      type: Boolean,
      default: false
    },
    countryEndpoint: {
      type: String,
      default: "countries"
    },
    contactSwitch: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    excludeAllNonEU: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    entity_placeholder: { ...defaultContactObject },
    contact: {},
    edit_entity: { ...defaultContactObject },
    isOk: false,
    cityEnabled: true
  }),
  watch: {
    edit_entity: {
      handler(val, old) {
        this.updateIsOk();
        if (val) this.$emit("update:entity", val);
      },
      deep: true
    },
    isOk: function(val) {
      this.$emit("validate", val);
    },
    "edit_entity.contact_id": function(val) {
      if (val !== null) {
        const index = this.$_.findKey(this.contacts, contact => {
          return contact.id === val;
        });

        if (index !== undefined) this.contact = { ...this.contacts[index] };
      } else {
        this.contact = {};
      }
    },
    contact: function(val) {
      const entity = val ? { ...this.contact } : { ...this.entity_placeholder };

      this.edit_entity.company_name = entity.company_name;
      this.edit_entity.name = entity.name;
      this.edit_entity.street1 = entity.street1;
      this.edit_entity.street2 = entity.street2;
      this.edit_entity.zip_code = entity.zip_code;
      this.edit_entity.city = entity.city;
      this.edit_entity.email = entity.email;
      this.edit_entity.phone = entity.phone;
      this.edit_entity.country = entity.country;

      this.updateIsOk();
    }
  },
  computed: {
    contact_id: function() {
      if (this?.edit_entity?.contact_id) return this.edit_entity.contact_id;
      else return 0;
    }
  },
  mounted() {
    this.setEntity(this.entity);
  },
  methods: {
    autoCity: _.debounce(autoCity, 250),
    toggleCityField: function(val) {
      this.cityEnabled = val;
    },
    update: function(key, val) {
      if (key && val) {
        let entity = { ...this.edit_entity };
        entity[key] = val;
        this.edit_entity = entity;
        this.$emit("update:entity", this.edit_entity);
        this.updateIsOk();
        if (key == "country") {
          let country = this.$_.find(this.$store.state.countries, function(
            country
          ) {
            return country.iso == val;
          });
          let entity = { ...this.edit_entity };
          this.edit_entity = entity;
          this.$emit("update:entity", this.edit_entity);
          this.updateIsOk();
        }
      }
    },
    updatePrefix: function(val) {
      if (val) {
        let entity = { ...this.edit_entity };
        let country = this.$_.find(this.$store.state.countries, function(
          country
        ) {
          return country.iso == val;
        });
        let prefix = '+' + country.phone_prefix;
        entity.phone = prefix;
        this.edit_entity = entity;
        this.$emit("update:entity", this.edit_entity);
        this.updateIsOk();
      }
    },
    updateIsOk: function() {
      let edit_entity = Object.assign(
        { ...this.entity_placeholder },
        { ...this.edit_entity }
      );
      delete edit_entity["contact_id"];

      if (edit_entity.type !== "business") delete edit_entity["company_name"];
      if (edit_entity.type !== "business" || !edit_entity["street2"])
        delete edit_entity["street2"];

      this.isOk = !this.$_.values(edit_entity).some(val => {
        return !val;
      });

      this.$emit("validate", this.isOk);
    },
    clear() {
      const entity = this.entity_placeholder;
      this.setEntity(entity);
    },
    setEntity: function(val) {
      const entity = {
        country: this.defaultCountry,
        type: this.defaultType,
        ...val
      };

      this.$_.each(Object.keys(entity), key => {
        this.edit_entity[key] = entity[key];
      });
      this.updateIsOk();
      this.$emit("update:entity", this.edit_entity);
    }
  }
};
</script>

<style scoped></style>
